(function () {
	'use strict';

	angular
		.module('event')
		.directive('readOnlyField', [readOnlyField])
		.directive('eventPlayback', [eventPlayback])
		.directive('editEvent', ['environment', editEvent])
		.directive('editDrawer', ['environment', editDrawer])
		.directive('panelSelector', [
			'$timeout',
			'$translate',
			'calendarIO',
			'seedcodeCalendar',
			'utilities',
			panelSelector,
		])
		.directive('datePickerStart', ['environment', datePickerStart])
		.directive('datePickerEnd', ['environment', datePickerEnd])
		.directive('calendarSelector', ['environment', calendarSelector])
		.directive('listSelector', ['environment', listSelector])
		.directive('locationSelector', ['environment', locationSelector])
		.directive('resourceSelector', [
			'environment',
			'seedcodeCalendar',
			'manageFilters',
			'manageResources',
			'manageSettings',
			resourceSelector,
		])
		.directive('statusSelector', ['environment', statusSelector])
		.directive('contactSelector', ['environment', contactSelector])
		.directive('projectSelector', ['environment', projectSelector])
		.directive('relatedResourceSelector', [
			'environment',
			relatedResourceSelector,
		])
		.directive('customFields', ['environment', customFields])
		.directive('eventActions', ['environment', eventActions])
		.directive('textHeight', [
			'$rootScope',
			'$timeout',
			'seedcodeCalendar',
			textHeight,
		])
		.directive('mapImage', ['$timeout', mapImage])
		.directive('quickCalendarPicker', [
			'$timeout',
			'environment',
			quickCalendarPicker,
		])
		.directive('customNumber', [
			'fieldFormatting',
			'environment',
			customNumber,
		])
		.directive('customDate', ['fieldFormatting', 'environment', customDate])
		.directive('interventions', ['environment', interventions])
		.directive('quickEdit', [quickEdit])
		.directive('richTextEditor', ['$timeout', richTextEditor])
		.directive('attachment', ['$timeout', attachment]);

	function readOnlyField() {
		return {
			restrict: 'EA',
			replace: true,
			link: function (scope, element, attrs) {},
			template:
				'<i tool-tip="Read-only field" enable="true" data-toggle="tooltip" data-placement="top" class="fa fa-lock dbk_label read-only-field-indicator"></i>',
		};
	}

	function eventPlayback() {
		return {
			restrict: 'EA',
			templateUrl: 'app/event/playback.html',
		};
	}

	function editEvent(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/mobile/event/event-edit.html'
				: 'app/event/event-edit.html',
		};
	}

	function quickEdit() {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				scope.$watch(
					function () {
						return scope.edit && scope.edit.interventions
							? scope.edit.interventions.length
							: false;
					},
					function (newValue, oldValue) {
						var elementHeight = element.height();
						if (!scope.edit.selectedPanelItem) {
							element[0].style.minHeight = elementHeight + 'px';
						}
					}
				);
			},
		};
	}

	function interventions(environment) {
		return {
			restrict: 'EA',
			templateUrl: 'app/event/interventions.html',
		};
	}

	function quickCalendarPicker($timeout, environment) {
		return {
			restrict: 'EA',
			link: function (scope, element, attrs) {
				var quickEditElement = $('.utility-drawer-lower');
				scope.updateCalendarMargin = updateMargin;

				scope.toggleHeight = function () {
					if (!scope.edit.selectedPanelItem) {
						quickEditElement[0].style.minHeight =
							quickEditElement.height() + 'px';
					}
					updateMargin();
					scope.togglePanel('calendar');
				};

				function updateMargin() {
					var labelElement = element.find('.widget-label');
					var labelWidth = labelElement.outerWidth();
					scope.listStyle = {
						marginLeft: labelWidth + 'px',
					};
				}
			},
			template: function (tElement, tAttributes) {
				var template =
					'<div ng-click="toggleHeight();">' +
					"<span class=\"widget-label\"><span translate>add to calendar</span>:</span><span class=\"fade fade-in\" ng-class=\"{'fade-out': edit.selectedPanelItem == 'calendar'}\"><span class='calendar-swatch-small'><span class='calendar-swatch' ng-attr-style='background: {{edit.schedule.backgroundColor}}'></span></span><span>{{edit.schedule.name}}</span></span>" +
					'</div>' +
					'<div ng-if="edit.selectedPanelItem === \'calendar\'" style="height: 246px;">' +
					'<calendar-selector></calendar-selector>' +
					'</div>' +
					'</div>';
				return template;
			},
		};
	}

	function customNumber(fieldFormatting, environment) {
		return {
			restrict: 'EA',
			scope: {
				number: '=',
				changeFunction: '&',
				fieldId: '@',
				fieldLabel: '@',
			},
			link: function (scope, element, attrs) {
				var align = attrs.align;

				var options = {
					thousandsSeparator: attrs.thousandsSeparator,
					decimalCharacter: attrs.decimalCharacter,
					decimalPlaces: attrs.decimalPlaces,
					numberLabel: attrs.numberLabel,
					numberLabelPosition: attrs.labelPosition,
				};

				//Set read only state
				scope.readOnly = attrs.readOnly === 'true';

				scope.enterField = enterField;
				scope.exitField = exitField;
				scope.onChangeCallback = onChangeCallback;
				scope.formatDisplayNumber = formatDisplayNumber;
				scope.getInputNumber = getInputNumber;

				scope.onChange = function ($event) {
					scope.changeFunction()(
						$event,
						scope.fieldId,
						getInputNumber(scope.displayNumber, false).number,
						scope.number,
						scope.fieldLabel,
						onChangeCallback
					);
				};

				//Init number
				formatDisplayNumber(null, scope.number, false);

				function exitField($event) {
					if (
						!$event ||
						($event.keyCode !== 13 && $event.keyCode !== 10)
					) {
						return;
					}
					var target = $event.target;
					target.blur();
				}

				function onChangeCallback(value, reverted) {
					if (reverted) {
						formatDisplayNumber(true, value, true);
					}
				}

				function enterField($event, number) {
					var inputElement = element.find('input');
					var originalLength = scope.displayNumber
						? scope.displayNumber.length
						: 0;
					formatDisplayNumber($event, number, true);
					var newLength = scope.displayNumber
						? scope.displayNumber.length
						: 0;
					var offset = newLength - originalLength;

					if (align !== 'right') {
						return;
					}
					//We need to wait slightly to give the cursor time to activate
					window.setTimeout(function () {
						var cursorPosition = inputElement[0].selectionStart;
						var newPosition =
							cursorPosition === 0 ? 0 : cursorPosition - offset;
						setCaretPosition(inputElement[0], newPosition);
					}, 100);
				}

				function formatDisplayNumber(
					$event,
					numberInput,
					preventRounding
				) {
					//If we are evaluating from no user input and the value is blank do nothing
					if (!numberInput && numberInput !== 0) {
						if (!$event) {
							return;
						} else {
							numberInput = '';
						}
					}

					var numberResult = getInputNumber(
						numberInput,
						preventRounding
					);

					//If the field is empty then we don't want to write zero we set an empty value
					if (!numberResult) {
						scope.displayNumber = '';
						scope.number = '';
						return;
					}

					//If this is set with user input write changed value back to original number field
					if ($event && scope.number !== numberInput) {
						scope.number = numberResult.number;
					}

					scope.displayNumber = numberResult.formattedNumber;
				}

				function getInputNumber(numberInput, preventRounding) {
					var result = fieldFormatting.formatDisplayNumber(
						numberInput,
						options,
						preventRounding
					);
					return result;
				}

				function setCaretPosition(elem, caretPos) {
					var range;

					if (elem.createTextRange) {
						range = elem.createTextRange();
						range.move('character', caretPos);
						range.select();
					} else {
						if (elem.selectionStart !== undefined) {
							elem.setSelectionRange(caretPos, caretPos);
						}
					}
				}
			},
			template: function (tElement, tAttributes) {
				var align = tAttributes.align || 'left';
				// var change = tAttributes.change ? 'ng-change="' + tAttributes.change + '"' : '';
				var change = tAttributes.changeFunction
					? 'ng-change="onChange($event);"'
					: '';
				var template =
					'<input ng-model="displayNumber" ng-keypress="exitField($event)" ng-focus="enterField($event, number)" ng-blur="formatDisplayNumber($event, displayNumber, false)" ng-readonly="readOnly" type="text" class="form-control text-' +
					align +
					'" placeholder="" autocomplete="off" ' +
					change +
					'>';
				return template;
			},
		};
	}

	function customDate(fieldFormatting, environment) {
		return {
			restrict: 'EA',
			scope: {
				clickAction: '&',
				date: '=',
			},
			link: function (scope, element, attrs) {
				var displayFormat = attrs.displayFormat;
				scope.editable = attrs.readOnly !== 'true';
				scope.$watch('date', function (newValue, oldValue) {
					scope.displayDate = fieldFormatting.formatDate(
						scope.date,
						displayFormat
					);
				});
			},
			template: function (tElement, tAttributes) {
				var datePickerTemplate =
					'<div ng-if="editable" ng-click="clickAction()" class="input-group input-group-with-btn">' +
					'<input ng-model="displayDate" readonly type="text" tabindex="-1" class="form-control unselectable clickable" placeholder="" autocomplete="off">' +
					'<div class="input-group-btn">' +
					'<button type="button" class="btn btn-default form-control-btn input-icon"><i class="fa fa-calendar"></i></button>' +
					'</div>' +
					'</div>' +
					'<input ng-if="!editable" ng-model="displayDate" readonly type="text" class="form-control" placeholder="" autocomplete="off">';

				var dateTimeTemplate =
					'<div ng-if="editable" ng-click="clickAction()" class="input-group input-group-textarea input-group-with-btn">' +
					'<textarea ng-model="displayDate" readonly rows="2" type="text" tabindex="-1" class="form-control unselectable clickable no-resize" placeholder="" autocomplete="off"></textarea>' +
					'<div class="input-group-btn">' +
					'<button type="button" class="btn btn-default form-control-btn input-icon"><i class="fa fa-calendar"></i></button>' +
					'</div>' +
					'</div>' +
					'<input ng-if="!editable" ng-model="displayDate" readonly type="text" class="form-control" placeholder="" autocomplete="off">';

				return tAttributes.hasTime
					? dateTimeTemplate
					: datePickerTemplate;
			},
		};
	}

	function editDrawer(environment) {
		return {
			restrict: 'E',
			scope: false,
			templateUrl: environment.isPhone
				? 'app/mobile/event/event-edit-panel.html'
				: 'app/event/event-edit-panel.html',
			link: function (scope, element, attrs) {
				var preventChange;
				// Don't let an end date exist without a start date
				scope.$watch(
					function () {
						return scope.edit.end;
					},
					function (newValue, oldValue) {
						if (scope.edit.end && !scope.edit.start) {
							scope.edit.start = scope.edit.end.clone();
						}
					}
				);

				//Watcher to check if start date is after end date. If it is we adjust the end date.
				scope.$watch(
					function () {
						return scope.edit.start;
					},
					function (newValue, oldValue) {
						var difference;
						var changed;
						var start = scope.edit.start
							? moment.utc(scope.edit.start.toArray())
							: scope.edit.start;
						var end = scope.edit.end
							? moment.utc(scope.edit.end.toArray())
							: scope.edit.end;

						//there are some schedules that need to heve the recurring rule removed if the date is changed.
						if (
							scope.edit.repeatingConfig &&
							scope.edit.repeatingConfig.dateChangeClear &&
							scope.edit.ruleObject
						) {
							scope.edit.schedule.source.clearRecurringFields(
								scope.edit
							);
							scope.edit.isRepeating = false;
						}

						//Change dates to utc so math ignores timezones
						oldValue = oldValue
							? moment.utc(oldValue.toArray())
							: oldValue;
						newValue = newValue
							? moment.utc(newValue.toArray())
							: newValue;

						//Keep end date in sync if we are increasing time
						if (!oldValue && !newValue) {
							changed = false;
						} else if (!preventChange && !oldValue && newValue) {
							changed = true;
							end = newValue.clone();
						} else if (
							!preventChange &&
							newValue.isAfter(oldValue)
						) {
							changed = true;
							difference = newValue.diff(oldValue);
							end.add(difference);
						}
						//Keep end date in sync if we are decreasing time
						else if (
							!preventChange &&
							newValue.isBefore(oldValue)
						) {
							changed = true;
							difference = oldValue.diff(newValue);
							end.subtract(difference);
						}
						//Safety check to make sure our new date isn't after end time
						if (newValue && end && newValue.isAfter(end)) {
							changed = true;
							end = newValue.clone();
						}
						if (changed) {
							scope.edit.end = moment(end.toArray());
						}
						preventChange = false;
					}
				);
				scope.$watch(
					function () {
						return scope.edit.end;
					},
					function (newValue, oldValue) {
						if (
							newValue &&
							scope.edit.start &&
							newValue.isBefore(scope.edit.start)
						) {
							preventChange = true;
							scope.edit.start = newValue.clone();
						}
					}
				);
			},
		};
	}

	function panelSelector(
		$timeout,
		$translate,
		calendarIO,
		seedcodeCalendar,
		utilities
	) {
		return {
			restrict: 'EA',
			replace: true,
			transclude: true,
			scope: {
				show: '=', //Wether to show the panel or not
				selected: '=', // The item that is currently selected
				content: '@', // The content to display.
				contentId: '=', // Id of the content item. This is used for viewing details if appropriate.
				name: '@', // The name of the selector item, used to match if it is currently selected.
				displayField: '@', // The name of the field for display if different than name.
				showTime: '=', // This tells us if we are showing the time selector or not.
				detailFunction: '=', //The controller function to run when clicking the view detail button
				picklistFunction: '=', //The controller function to run to retrive the picklist
				disabled: '@',
				edit: '=',
				isDefaultData: '=',
				iconActive: '&',
				isReadOnlyField: '=',
			},
			link: function (scope, element, attrs) {
				var readonlyAccess = attrs.readonlyAccess === 'true';

				// Set if the field is marked as read only
				// scope.isReadOnlyField = scope.edit.readOnlyField
				// 	? scope.edit.readOnlyField[readOnlyFieldName]
				// 	: false;

				scope.name = attrs.name;
				scope.fieldName = scope.displayField || scope.name;
				scope.readonlyAccess = readonlyAccess;
				scope.editableOverride = attrs.editableOverride === 'true';
				scope.detail = detail; // Assign detail function to scope

				//Manage tooltips
				if (scope.isDefaultData) {
					//Enable any tooltips
					$('.edit-panel-default-' + scope.name).tooltip({
						container: 'body',
					});
					//Destroy tooltip when closing popover
					scope.$on('$destroy', function (e) {
						$('.edit-panel-default-' + scope.name).tooltip(
							'destroy'
						);
					});
				}

				if (attrs.iconState) {
					scope.iconHasState = true;
				}

				scope.panelSelect = function (item) {
					if (scope.picklistFunction) {
						scope
							.picklistFunction(scope.name)
							.then((result) => {
								// do something on success
							})
							.catch((err) => {
								// do something on error
							});
					}

					if (
						(!scope.edit.editable ||
							scope.edit.readOnlyField[scope.fieldName]) &&
						!readonlyAccess &&
						!scope.editableOverride
					) {
						return false;
					}

					if (scope.showTime) {
						scope.showTime = false; //We set this to false so when switching panels we don't get the time picker popping up
					}

					if (item === scope.selected && scope.show) {
						scope.show = false;
						$timeout(function () {
							if (!scope.show) {
								scope.selected = null;
							}
						}, 500);
					} else {
						scope.selected = item;
						scope.show = true;
					}

					if (scope.edit && scope.edit.secondPanel) {
						$timeout(function () {
							scope.edit.secondPanel = null; //We need to unset any secondary panels
							scope.edit.secondPanelItemSelected = null; //And unset any items selected in the second panel
						}, 500);
					}
				};

				scope.contentStyle = {};
				scope.childContentStyle = {};

				//Watch for width changes on the label so we can adjust the content width accordingly
				scope.$watch(
					function () {
						var eventSource =
							scope.edit && scope.edit.eventSource
								? scope.edit.eventSource + scope.edit.todo
								: '';
						var labelOverride =
							scope.edit.labelMapOverride &&
							scope.edit.labelMapOverride[scope.fieldName];
						return (
							eventSource ||
							labelOverride ||
							scope.edit[scope.fieldName]
						);
					},
					function (newValue, oldValue) {
						var labelElement;
						//Initialize elements with zero width so we don't flash scroll bars before timeout can run
						scope.contentStyle.width = 0 + 'px';
						scope.childContentStyle.width = 0 + 'px';
						var labelWidth;
						//Run in timeout because if we are switching calendars visible fields might change and we need to evaluate after elements are shown
						$timeout(function () {
							labelElement = angular
								.element(element)
								.find('.edit-label');
							applyWidth();
						}, 0);

						function applyWidth() {
							var panelInnerWidth = parseInt(
								window.getComputedStyle(element[0]).width,
								10
							);
							labelWidth = labelElement.width();
							scope.contentStyle.width =
								'calc(100% - ' + (labelWidth + 2) + 'px)';
							scope.childContentStyle.width =
								'calc(100% - ' + (labelWidth + 32) + 'px)';
							//Set max width as a bug fix in firefox - Without max width firefox will allow the content to extend past container
							scope.contentStyle.maxWidth =
								panelInnerWidth - (labelWidth + 2) + 'px';
							scope.childContentStyle.maxWidth =
								panelInnerWidth - (labelWidth + 32) + 'px';
						}
					}
				);

				// Assign class values stored in associated filter items. Just resources for now
				scope.$watch('content', function (newValue, oldValue) {
					var items;
					var classList = [];
					if (
						attrs.name === 'resource' &&
						scope.edit[attrs.name] &&
						scope.edit[attrs.name].length
					) {
						items = seedcodeCalendar.get('resources');
						for (
							var i = 0;
							i < scope.edit[attrs.name].length;
							i++
						) {
							for (var ii = 0; ii < items.length; ii++) {
								if (
									scope.edit[attrs.name][i] ===
										items[ii].name &&
									items[ii].class
								) {
									classList.push(items[ii].class);
								}
							}
						}
					}
					scope.contentClass = classList.join(' ');
				});

				function detail(recordID, edit, name) {
					// Running this as an event action with a callback
					// Not passing in any action detail will run the save routine if necessary
					// Then it will execute the callback function without running an actual event action
					calendarIO.eventAction(
						edit,
						null,
						gotoDetail,
						false,
						null,
						null,
						null,
						null,
						null
					);

					function gotoDetail() {
						scope.detailFunction(recordID, edit, name);
					}
				}
			},

			template: function (tElement, tAttributes) {
				var templateContent;

				var tooltip = tAttributes.isDefaultData
					? '<span ng-show="isDefaultData" class="edit-panel-default-data edit-panel-default-' +
					  tAttributes.name +
					  '" data-toggle="tooltip" data-placement="top" title="' +
					  $translate.instant(
							tAttributes.name + ' inherited from calendar'
					  ) +
					  '">*</span>'
					: '';

				var standard =
					'<read-only-field ng-if="edit.readOnlyField[fieldName]"></read-only-field>' +
					'<span ng-style="contentStyle" class="pull-right text-right clamp" ng-bind="content"></span>' +
					tooltip;

				var html =
					'<read-only-field ng-if="edit.readOnlyField[fieldName]"></read-only-field>' +
					'<span ng-style="contentStyle" class="pull-right text-right clamp" ng-bind-html="content | html"></span>' +
					tooltip;

				var showIcon =
					'<read-only-field ng-if="edit.readOnlyField[fieldName]"></read-only-field>' +
					'<i ng-if="contentId && detailFunction" class="' +
					tAttributes.showIcon +
					' fa-lg pull-right navigation" ng-class="{\'inactive\': iconHasState && !iconActive()}" style="margin-left: 6px;" ng-click="detailFunction(content); $event.stopPropagation()"></i>' +
					'<i ng-if="contentId && !detailFunction" class="' +
					tAttributes.showIcon +
					' fa-lg pull-right navigation" ng-class="{\'inactive\': iconHasState && !iconActive()}" style="margin-left: 6px;"></i>' +
					'<span ng-style="childContentStyle" class="pull-right text-right clamp" ng-class="{\'default-data\': isDefaultData}" ng-bind="content"></span>' +
					tooltip;

				var viewDetail =
					'<read-only-field ng-if="edit.readOnlyField[fieldName]"></read-only-field>' +
					'<i ng-if="contentId.length && detailFunction && !edit.isShare" ng-click="detail(contentId, edit, name); $event.stopPropagation();" class="fa fa-chevron-circle-right fa-lg pull-right navigation dbk_icon dbk_icon_chevron_circle_right" style="margin-left: 6px;"></i>' +
					'<span ng-style="childContentStyle" class="pull-right text-right clamp" ng-class="{\'default-data\': isDefaultData}" ng-bind="content"></span>' +
					tooltip;

				if (tAttributes.showDetail == 'true') {
					templateContent = viewDetail;
				} else if (tAttributes.showIcon) {
					templateContent = showIcon;
				} else if (tAttributes.html == 'true') {
					templateContent = html;
				} else {
					templateContent = standard;
				}

				return (
					"<div ng-click=\"panelSelect(name)\" class=\"panel-selector clearfix dbk_listSelectButton{{(contentClass ? ' ' + contentClass : '')}}{{(isReadOnlyField ? ' ' + 'read-only-field' : '')}}\" ng-class=\"{'panelActivate dbk_selected': show && selected == name, 'read-only': (!edit.editable || edit.readOnlyField[fieldName]) && !readonlyAccess && !editableOverride, 'disabled-hidden': disabled == 'true'}\">" +
					'<span class="edit-label pull-left dbk_label" ng-transclude></span>' +
					templateContent +
					'</div>'
				);
			},
		};
	}

	function datePickerStart(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/mobile/event/date-start.html'
				: 'app/event/date-start.html',
		};
	}
	function datePickerEnd(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/mobile/event/date-end.html'
				: 'app/event/date-end.html',
		};
	}
	function calendarSelector(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/mobile/event/calendar-selector.html'
				: 'app/event/calendar-selector.html',
		};
	}
	function listSelector(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/mobile/event/list-selector.html'
				: 'app/event/list-selector.html',
		};
	}
	function locationSelector(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/mobile/event/location.html'
				: 'app/event/location.html',
		};
	}
	function resourceSelector(
		environment,
		seedcodeCalendar,
		manageFilters,
		manageResources,
		manageSettings
	) {
		return {
			restrict: 'EA',
			controller: function ($scope) {
				$scope.props = {
					findTextInFields: manageFilters.findTextInFields,
					filteredResources: manageResources.getFiltered(),
					editEvent: $scope.edit,
					selectResource: $scope.selectResource,
					nameToFilterItem: manageSettings.nameToFilterItem,
					picklistFunc: $scope.picklist,
				};
			},

			templateUrl: environment.isPhone
				? 'app/mobile/event/resource-selector.html'
				: 'app/event/resource-selector.html',
		};
	}
	function statusSelector(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/mobile/event/status-selector.html'
				: 'app/event/status-selector.html',
		};
	}
	function contactSelector(environment) {
		var template = 'app/event/contact-selector.html';
		var mobiletemplate = 'app/mobile/event/contact-selector.html';
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone ? mobiletemplate : template,
		};
	}
	function projectSelector(environment) {
		var template = 'app/event/project-selector.html';
		var mobiletemplate = 'app/mobile/event/project-selector.html';
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone ? mobiletemplate : template,
		};
	}
	function relatedResourceSelector(environment) {
		var template = 'app/event/related-resource-selector.html';
		var mobiletemplate = 'app/mobile/event/related-resource-selector.html';
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone ? mobiletemplate : template,
		};
	}
	function customFields(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/mobile/event/custom-fields.html'
				: 'app/event/custom-fields.html',
		};
	}
	function eventActions(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/mobile/event/event-actions.html'
				: 'app/event/event-actions.html',
		};
	}

	function textHeight($rootScope, $timeout, seedcodeCalendar) {
		return {
			restrict: 'EA',
			scope: {
				unused: '=',
			},
			link: function (scope, element, attrs) {
				var textHeight = Number(attrs.textHeight);
				var customOffset = attrs.customOffset == 'true';
				var eventDictionary = seedcodeCalendar.get('eventDictionary');
				var id = element[0].id;

				scope.$watchCollection('unused', function (newValue, oldValue) {
					var rowCount = Number(attrs.rowCount);

					//Reset the element height to auto - We do this so if the unused count changes we properly resize the field (switching calendars for example)
					element[0].style.height = 'auto';

					$timeout(function () {
						var panelSelector;
						var panelSelectorHeight;
						var textRows;
						var height;
						var unused = 0;
						var rows = element[0].rows;
						var topRowUnused = 0;
						//Passing in an explicit height
						if (textHeight) {
							height = textHeight;
						}
						//We need to resize due to unused items
						else if (
							(scope.unused && id !== 'title') ||
							(scope.unused &&
								scope.unused.description &&
								id === 'title') ||
							customOffset
						) {
							panelSelector =
								document.querySelector('.panel-selector');
							if (panelSelector) {
								panelSelectorHeight =
									panelSelector.clientHeight;
							} else {
								panelSelectorHeight = 0;
							}
							textRows = rows
								? element[0].clientHeight / (rows || 1)
								: 1;
							//Count unused items
							unused = 0;
							for (var property in scope.unused) {
								if (
									scope.unused[property] === true &&
									property.indexOf('ID') === -1 &&
									(eventDictionary[property] ||
										property === 'calendar' ||
										property === 'customFields') &&
									property !== 'geocode' &&
									property !== 'tags' &&
									property !== 'done' &&
									property !== 'todo' &&
									property !== 'hasAttachments' &&
									property !== 'hasImages' &&
									property !== 'attachmentsURL' &&
									property !== 'parentListName' &&
									property !== 'featuredImage' &&
									property !== 'featuredImageClass' &&
									property !== 'featuredImageThumbnail' &&
									property !==
										'featuredImageThumbnailClass' &&
									property !== 'unscheduled' &&
									property !== 'allDay' &&
									property !== 'repeatAllowed'
								) {
									unused++;
								}

								if (
									property === 'allDay' ||
									property === 'repeatAllowed'
								) {
									topRowUnused++;
								}
							}

							// if both all day and repeat allowed are unused / hidden then treat that as another unused slot
							if (topRowUnused > 1) {
								unused++;
							}

							height =
								(rowCount +
									Math.floor(
										(unused * panelSelectorHeight) /
											textRows
									)) *
								textRows;

							if (customOffset) {
								height = height - panelSelectorHeight;
							}
						}

						if (height) {
							//Set element height
							element[0].style.height = height + 'px';
						}

						//Broadcast that we have resized this field
						$rootScope.$broadcast('resize-' + id, height);
					}, 0);
				});
			},
		};
	}
	function mapImage($timeout) {
		return {
			restrict: 'EA',
			scope: {
				address: '=',
				map: '=',
			},
			link: function (scope, element, attrs) {
				var containerWidth;
				var containerHeight;

				scope.$watch('address', function (newValue, oldValue) {
					$timeout(function () {
						containerWidth = element.width();
						containerHeight = element.height();
						var url = getMapURL(
							scope.address,
							scope.map.zoom,
							containerWidth,
							containerHeight
						);
						createMap(url, containerWidth * 2, containerHeight * 2);
					}, 0);
				});

				function getMapURL(address, zoom, width, height) {
					if (!address) {
						return '';
					}
					var addressEncoded = encodeURIComponent(address);
					var centerString = '&center=' + addressEncoded;
					var zoomString = zoom ? '&zoom=' + zoom : '';
					var iconURL =
						'https://app.dayback.com/images/map-marker-blue.png';
					var url =
						'https://maps.googleapis.com/maps/api/staticmap?' +
						'size=' +
						Math.round(width) +
						'x' +
						Math.round(height) +
						zoomString +
						centerString +
						'&scale=2' +
						'&style=feature:all%7Celement:all%7Ccolor:0x2d3133' +
						'&style=feature:all%7Celement:geometry.stroke%7Cvisibility:off' +
						'&style=feature:all%7Celement:labels%7Cvisibility:on' +
						'&style=feature:all%7Celement:labels.text.fill%7Ccolor:white%7Cvisibility:on' +
						'&style=feature:all%7Celement:labels.text.stroke%7Ccolor:0x000000%7Cweight:2%7Cvisibility:on' +
						'&style=feature:all%7Celement:labels.icon%7Cvisibility:off' +
						'&style=feature:landscape%7Celement:geometry%7Clightness:7%7Csaturation:30%7Cvisibility:on' +
						'&style=feature:landscape%7Celement:geometry.stroke%7Clightness:-43%7Cvisibility:simplified' +
						'&style=feature:poi%7Celement:geometry%7Csaturation:20' +
						'&style=feature:poi.park%7Celement:geometry%7Clightness:20%7Csaturation:20' +
						'&style=feature:road%7Celement:geometry%7Clightness:10%7Csaturation:-30%7Cvisibility:on' +
						'&style=feature:road%7Celement:geometry.fill%7Clightness:-100%7Cvisibility:on' +
						'&style=feature:road%7Celement:geometry.stroke%7Csaturation:25%7Clightness:18%7Cvisibility:on' +
						'&style=feature:water%7Celement:all%7Clightness:-20' +
						'&markers=icon:' +
						iconURL +
						'%7C' +
						addressEncoded +
						'&key=AIzaSyCLJWg3BR3qcIQ4XQNb2TNY2WGYlbPSZc4';
					return url;
				}

				function createMap(url, width, height) {
					if (!url) {
						return;
					}

					//Get existing canvas element if it exists
					var existingCanvasElement = element[0].childNodes[0];
					// Create an in-memory canvas and store its 2d context
					var mapElement = existingCanvasElement;
					mapElement.setAttribute('width', width);
					mapElement.setAttribute('height', height);
					var mapContext = mapElement.getContext('2d');
					mapContext.imageSmoothingEnabled = false;
					//Make sure to clear the canvas before writing to it
					mapContext.clearRect(0, 0, width, height);
					var map = new Image();
					map.crossOrigin =
						'http://maps.googleapis.com/crossdomain.xml';
					map.src = url;

					map.onload = function () {
						// Put the map image inside the canvas once it loads
						mapContext.drawImage(map, 0, 0, width, height);

						//Check if google found a location
						if (!isMarker()) {
							mapContext.clearRect(0, 0, width, height);
							scope.$evalAsync(function () {
								scope.map.error = true;
							});
						}
					};

					//Function to determine if a marker is displayed (matches against a marker color)
					function isMarker() {
						// Get the RGB colors at the given pixels
						var pixels = mapContext.getImageData(
							width / 2,
							height / 2 - 10,
							1,
							1
						).data;
						for (var i = 0; i < pixels.length; i += 4) {
							var red = pixels[i];
							var green = pixels[i + 1];
							var blue = pixels[i + 2];
							var alpha = pixels[i + 3];

							//We check a range in rgb values as Google has changed the default pin color slightly in the past
							if (
								red >= 30 &&
								red <= 40 &&
								green >= 135 &&
								green <= 145 &&
								blue >= 240 &&
								blue <= 250
							) {
								return true;
							}
						}
						return false;
					}
				}
			},
			template:
				'<canvas ng-show="address && !map.error" disable-drag class="custom-map"></canvas>',
		};
	}

	function attachment($timeout) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				//Wrap in a timeout so our bindHTML directive has a chance to run first
				$timeout(function () {
					var attachmentElements =
						element[0].getElementsByTagName('bc-attachment');
					var attachmentProperties = {};
					var attachmentButtons = '';

					if (!attachmentElements.length) {
						return;
					}

					for (var i = 0; i < attachmentElements.length; i++) {
						attachmentProperties.previewURL =
							attachmentElements[i].getAttribute('url');
						attachmentProperties.downloadURL =
							attachmentElements[i].getAttribute('href');
						attachmentProperties.fileName =
							attachmentElements[i].getAttribute('filename');
						attachmentProperties.previewable =
							attachmentElements[i].getAttribute('previewable');
						if (attachmentProperties.previewable === 'true') {
							attachmentButtons +=
								'<div class="attachment-preview">' +
								'<img src="' +
								attachmentProperties.previewURL +
								'">' +
								'<div class="attachment-caption">' +
								'<div class="attachment-name">' +
								attachmentProperties.fileName +
								'</div>' +
								'<a href="' +
								attachmentProperties.downloadURL +
								'" target="_blank">Full Size</a>' +
								' | ' +
								'<a href="' +
								attachmentProperties.downloadURL +
								'?attachment=true" target="_blank">Download</a>' +
								'</div>' +
								'</div>';
						} else {
							attachmentButtons +=
								'<div class="attachment-btn">' +
								'<img src="' +
								attachmentProperties.previewURL +
								'">' +
								'<div class="attachment-caption">' +
								'<div class="attachment-name">' +
								attachmentProperties.fileName +
								'</div>' +
								'<a href="' +
								attachmentProperties.downloadURL +
								'?attachment=true" target="_blank">Download</a>' +
								'</div>' +
								'</div>';
						}

						attachmentElements[i].style.display = 'none';
					}
					element[0].insertAdjacentHTML(
						'beforeend',
						attachmentButtons
					);
				}, 0);
			},
		};
	}

	function richTextEditor($timeout) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				// console.log('linked', element);
				// // Initialize quill on an HTMLElement
				// var options = {
				// 	debug: 'info',
				// 	modules: {
				// 	  toolbar: '#toolbar'
				// 	},
				// 	placeholder: 'Compose an epic...',
				// 	readOnly: true,
				// 	theme: 'snow'
				//   };
				// var container = element[0];
				// var editor = new Quill(container, options);
			},
		};
	}
})();
